import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureCurrentUser, ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, AvatarSmall, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <p className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </p>
      {isBookable ? (
        <h2 className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </h2>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    onUpdateFavorites,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const username = author.attributes.profile.publicData?.username;
  const authorName = username ?? author.attributes.profile.displayName;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(
    listing.id.uuid
  );
  const [isWishlist, setIsWishlist] = useState(isFavorite);
  const location = useLocation();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const userIsCurrentUser = listing.author && listing.author.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(listing.author) : author;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isOwnListing = currentUser && listing.author?.id?.uuid === currentUser?.id?.uuid;

  const conditionStyles = classNames(
    css.condition,
    css[listing.attributes.publicData.condition_id]
  );

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const handleWishlistClick = e => {
    if (!ensuredCurrentUser?.id?.uuid) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}), state);
    } else {
      const {
        attributes: { profile },
      } = currentUser;
      const { favorites = [] } = profile.privateData || {};

      let payload;
      if (!profile.privateData || !profile.privateData?.favorites) {
        payload = {
          privateData: {
            favorites: [id],
          },
        };
      } else if (isWishlist) {
        payload = {
          privateData: {
            favorites: favorites.filter(f => f !== id),
          },
        };
      } else {
        payload = {
          privateData: {
            favorites: [...favorites, id],
          },
        };
      }
      onUpdateFavorites(payload);
      setIsWishlist(!isWishlist);
    }
  };

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
          {isOwnListing ? (
            <div className={css.ownListingOverlay}>
              <h1 className={css.ownListingTitle}>This is your own listing</h1>
            </div>
          ) : null}
        </AspectRatioWrapper>
        <div className={css.info}>
          <div className={css.headerInfo}>
            <h2 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h2>

            <h1 className={conditionStyles}>{publicData.condition_id}</h1>
          </div>
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        </div>
      </NamedLink>
      {showAuthorInfo ? <div className={css.divider}></div> : null}
      <NamedLink
        className={css.authorWrapper}
        name="ProfilePage"
        params={{ id: ensuredUser.id.uuid }}
      >
        {showAuthorInfo ? (
          <div className={css.authorInfo}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>
        ) : null}
      </NamedLink>

      {!isOwnListing ? (
        <div className={css.wishlist} onClick={handleWishlistClick}>
          <FontAwesomeIcon
            icon={!isWishlist ? faHeart : faHeartSolid}
            className={css.wishlistIcon}
          />
        </div>
      ) : null}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  currentUser: null,
  onUpdateFavorites: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  currentUser: propTypes.currentUser,
  // Responsive image sizes hint
  renderSizes: string,
  onUpdateFavorites: func,
  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
